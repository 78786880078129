const removeDecimalsIfInt = (number: number | null) => {
  if (!number) {
    return 0;
  }

  if (Number.isInteger(number)) {
    return parseInt(number.toString(), 10);
  }
  return number;
};

export default removeDecimalsIfInt;
