import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { ProgressOverview } from "../types";

const getProgressOverview = (
  accessToken: string,
  signal: AbortSignal,
  clinicianEmail?: string
) =>
  api
    .get<ProgressOverview>("/v4/clinicians/dashboard/progress", {
      signal,
      headers: {
        Authorization: accessToken,
      },
      params: { email: clinicianEmail },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useProgressOverview(
  options: UseQueryOptions<ProgressOverview, Error>,
  clinicianEmail?: string
): UseQueryResult<ProgressOverview, Error> {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).progressOverview(clinicianEmail),
    ({ signal }) => getProgressOverview(accessToken, signal, clinicianEmail),
    {
      enabled: !!accessToken,

      staleTime: clinicianEmail ? 0 : Infinity,
      ...(options ?? {}),
    }
  );
}
