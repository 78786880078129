import { useSession } from "@core/hooks/useSession";
import { UtilizationOverview } from "@core/services/nocd-api";
import { useQuery, UseQueryResult } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";

const getUtilizationOverview = (
  accessToken: string,
  signal: AbortSignal,
  clinicianEmail?: string,
  quarter?: string,
  selectedWeek?: string,
  selectedDay?: string
) =>
  api
    .get<UtilizationOverview>("/v1/clinicians/dashboard/progress/utilization", {
      signal,
      headers: {
        Authorization: accessToken,
      },
      params: {
        email: clinicianEmail,
        quarter,
        selected_week: selectedWeek,
        selected_day: selectedDay,
      },
    })
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useUtilizationOverview(
  clinicianEmail?: string,
  quarter?: string,
  selectedWeek?: string,
  selectedDay?: string
): UseQueryResult<UtilizationOverview, Error> {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).utilizationOverview(
      clinicianEmail,
      quarter,
      selectedWeek,
      selectedDay
    ),
    ({ signal }) =>
      getUtilizationOverview(
        accessToken,
        signal,
        clinicianEmail,
        quarter,
        selectedWeek,
        selectedDay
      ),
    { enabled: !!accessToken && !!quarter && !!clinicianEmail }
  );
}
