import useMembersPlanOfSuccession, {
  MembersPlanOfSuccessionResponse,
} from "@core/services/nocd-api/clinicians/queries/useMembersPlanOfSuccession";
import getBrowserTimeZone from "@core/utils/getBrowserTimeZone";
import getMemberFullNameAndLinkRoleIcon from "@core/utils/getMemberNameAndLinkRoleIcon";
import ClearIcon from "@mui/icons-material/Clear";
import RefreshIcon from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link as MuiLink,
  TextField,
  Theme,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {
  DataGridPro,
  GridColDef,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { isValid } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { escapeRegExp } from "lodash";
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";

import OptionsMenu from "./OptionsMenu";

type MemberRow = MembersPlanOfSuccessionResponse["members"][number];

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.5, 0.5, 0),
      justifyContent: "space-between",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
    },
    textField: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      margin: theme.spacing(1, 0.5, 1.5),
      "& .MuiSvgIcon-root": {
        marginRight: theme.spacing(0.5),
      },
      "& .MuiInput-underline:before": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  })
);

function CustomToolbar({
  refresh,
  searchText,
  onChangeSearchText,
  onClearSearchText,
  needsReviewOnly,
  onChangeNeedsReviewOnly,
}: {
  searchText: string | null;
  onChangeSearchText: (
    searchText: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onClearSearchText: () => void;
  needsReviewOnly: boolean;
  onChangeNeedsReviewOnly: (newValue: boolean) => void;
  refresh: () => void;
}) {
  const classes = useToolbarStyles();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <GridToolbarContainer>
        <GridToolbarFilterButton />

        <Button
          color="primary"
          onClick={refresh}
          startIcon={<RefreshIcon />}
          size="small"
        >
          Refresh
        </Button>

        <Box ml={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={needsReviewOnly}
                onChange={(event) =>
                  onChangeNeedsReviewOnly(event.target.checked)
                }
                name="needsReviewOnly"
                size="small"
                color="primary"
              />
            }
            label="Needs review only"
          />
        </Box>

        <GridToolbarExport />
      </GridToolbarContainer>

      <GridToolbarContainer>
        <TextField
          variant="standard"
          value={searchText}
          onChange={onChangeSearchText}
          placeholder="Search…"
          className={classes.textField}
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: searchText ? "visible" : "hidden" }}
                onClick={onClearSearchText}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </GridToolbarContainer>
    </Box>
  );
}

const PlanOfSuccessionMembers = ({
  clinicianEmail,
  view,
}: {
  clinicianEmail: string;
  view: "scheduler" | "clinician";
}) => {
  const [searchText, setSearchText] = useState("");
  const [needsReviewOnly, setNeedsReviewOnly] = useState(false);

  const { data, isLoading, refetch } = useMembersPlanOfSuccession(
    clinicianEmail,
    {
      select: useCallback(
        ({ members }) => {
          const searchRegex = new RegExp(escapeRegExp(searchText), "i");
          const filteredRows = members.filter((row: MemberRow) =>
            Object.keys(row).some((field: keyof MemberRow) =>
              searchRegex.test(row?.[field]?.toString())
            )
          );
          return filteredRows
            .map((member) => ({
              ...member,
              id: member.user_id,
            }))
            .filter((r) => {
              if (!needsReviewOnly) {
                return true;
              }

              return r.status === "Needs review";
            });
        },
        [searchText, needsReviewOnly]
      ),
    }
  );

  const columns: GridColDef<MemberRow>[] = useMemo(
    () => [
      {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        width: 130,
        renderCell: (params) => (
          <OptionsMenu
            userId={params.row.user_id}
            onSuccess={() => refetch()}
            disabled={params.row.status !== "Needs review"}
          />
        ),
      },
      {
        field: "user_id",
        headerName: "User ID",
        width: 100,
        renderCell: (params) => (
          <MuiLink
            href={`/members/${encodeURIComponent(+params.value)}${
              view === "scheduler" ? "/scheduling" : ""
            }`}
            target="_blank"
          >
            {params.value}
          </MuiLink>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        width: 260,
        valueGetter: (params) =>
          getMemberFullNameAndLinkRoleIcon(
            params.row.first_name,
            params.row.last_name,
            params.row.preferred_name,
            params.row.is_linked_account,
            params.row.is_root_account
          ),
      },
      {
        field: "email",
        headerName: "Email",
        width: 240,
      },
      {
        field: "most_recent_appt",
        headerName: "Last session",
        width: 175,
        valueGetter: (params) => {
          const value = params.value as string;
          const valueAsDate = value ? new Date(value) : null;
          return isValid(valueAsDate) ? valueAsDate : undefined;
        },
        valueFormatter: (params) =>
          params.value
            ? formatInTimeZone(
                params.value as string,
                getBrowserTimeZone(),
                "MM/dd/yyyy"
              )
            : "-",
      },
      {
        field: "status",
        headerName: "Status",
        width: 220,
        valueGetter: (params) => {
          return params.value ?? "-";
        },
      },
      {
        field: "special_conditions",
        headerName: "Conditions",
        valueFormatter: (params) =>
          Array.isArray(params.value) ? params.value.join(", ") : "-",
      },
    ],
    [refetch, view]
  );

  return (
    <DataGridPro
      density="compact"
      sx={{ height: 700 }}
      columns={columns}
      rows={data ?? []}
      loading={isLoading}
      autoPageSize
      pagination
      disableRowSelectionOnClick
      components={{
        Toolbar: CustomToolbar,
      }}
      componentsProps={{
        toolbar: {
          refresh: refetch,
          searchText,
          onChangeSearchText: (
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setSearchText(event.target.value),
          onClearSearchText: () => setSearchText(""),
          needsReviewOnly,
          onChangeNeedsReviewOnly: setNeedsReviewOnly,
        },
      }}
    />
  );
};

export default PlanOfSuccessionMembers;
