import { BanknotesIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

const BonusEligibilityExplanationDialog = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <BanknotesIcon
          style={{
            height: 24,
            width: 24,
            marginRight: 16,
          }}
        />
        How to qualify for Conqueror bonus
      </DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <Typography>
            To receive a bonus for helping members become Treatment and
            Sustained Conquerors, you need to meet the following requirements
            each quarter. Eligibility resets every quarter.
          </Typography>

          <Box component={Paper} p={3}>
            <Stack spacing={3}>
              <Stack>
                <Typography variant="button">Chart closure rate</Typography>
                <Typography variant="body2">
                  You need to sign <strong>95% or more</strong> of your notes
                  within 48 hours from the session start time.
                </Typography>
              </Stack>

              <Stack>
                <Typography variant="button">
                  Adjusted utilization hours
                </Typography>
                <Typography variant="body2">
                  Your utilization hours must be <strong>higher</strong> than
                  your committed hours.
                </Typography>
                <Typography variant="body2">
                  • Utilization hours refer to the time therapists spend in
                  direct, billable client care, PTOs, and late cancelations.
                </Typography>
                <Typography variant="body2">
                  • Committed hours are the total hours a therapist has agreed
                  to work within a week.
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Typography>
            If you have questions or need help meeting these requirements,
            please talk to your COM.
          </Typography>

          <Box>
            <Button variant="nocdIndigoButton" onClick={onClose}>
              Got it, thanks
            </Button>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BonusEligibilityExplanationDialog;
