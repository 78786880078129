import useProgressOverview from "@core/services/nocd-api/clinician-dashboard/queries/useProgressOverview";
import { SparklesIcon } from "@heroicons/react/16/solid";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Chip,
  Link as MuiLink,
  Skeleton,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { isEmpty } from "lodash/fp";

interface SessionCounterProps extends StackProps {
  clinicianEmail?: string | null;
}

const SessionCounter = ({ clinicianEmail, ...props }: SessionCounterProps) => {
  const isClinicianPage = !isEmpty(clinicianEmail);

  const {
    data: progressOverview,
    error: progressOverviewError,
    isLoading: isLoadingprogressOverview,
  } = useProgressOverview(
    {
      staleTime: clinicianEmail ? 0 : Infinity,
    },
    clinicianEmail ?? null
  );

  return (
    <Stack
      justifyContent="center"
      p={3}
      {...props}
      sx={{
        textAlign: "center",
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        height: "200px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography
            sx={{
              fontSize: "14px",
              color: "#ABAEB4",
              fontWeight: "700",
            }}
          >
            NOTES
          </Typography>
        </Stack>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            isLoadingprogressOverview ? (
              <Box width="200px">
                <Stack>
                  <Skeleton height="100%" width="100%" />
                </Stack>
              </Box>
            ) : progressOverviewError ? (
              <Typography color="error.main" variant="body2">
                Error loading stats
              </Typography>
            ) : (
              <>
                {progressOverview.clinician_unsigned_note_count === 0 ? (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <SparklesIcon
                        style={{
                          width: "16px",
                          height: "16px",
                          marginRight: "8px",
                          color: "#4ade80",
                        }}
                      />
                      <Typography sx={{ fontSize: "14px" }}>
                        You&apos;re caught up!
                      </Typography>
                      <SparklesIcon
                        style={{
                          width: "16px",
                          height: "16px",
                          marginLeft: "8px",
                          color: "#4ade80",
                          transform: "scaleX(-1)",
                        }}
                      />
                    </Box>
                  </Stack>
                ) : (
                  <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    paddingTop="2px"
                  >
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      paddingTop="2px"
                    >
                      <Typography sx={{ fontSize: "28px", fontWeight: "900" }}>
                        {progressOverview?.clinician_unsigned_note_count}
                      </Typography>
                      <Typography sx={{ marginLeft: "3px" }}>
                        unsigned
                      </Typography>
                    </Stack>

                    {progressOverview?.clinician_overdue_note_count > 0 ? (
                      <Box ml={1} mb={2}>
                        <Chip
                          size="small"
                          icon={
                            <ExclamationCircleIcon
                              style={{
                                width: "20px",
                                height: "20px",
                                color: "#FF7865",
                              }}
                            />
                          }
                          label={
                            <>
                              <Typography component="span" fontWeight="bold">
                                {progressOverview?.clinician_overdue_note_count}
                              </Typography>{" "}
                              overdue
                            </>
                          }
                          sx={{
                            backgroundColor: "#FFF5F3",
                            color: "#FF7865",
                            "& .MuiChip-icon": {
                              marginLeft: "6px",
                            },
                          }}
                        />
                      </Box>
                    ) : null}
                  </Stack>
                )}
              </>
            )
          }
        </Box>
      </Box>

      {!isClinicianPage &&
      progressOverview?.clinician_unsigned_note_count > 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MuiLink
            href="/appointments"
            target="_blank"
            underline="none"
            color="nocdBlue.main"
            fontWeight={400}
            fontSize="12px"
            sx={{
              whiteSpace: "nowrap",
              "&:hover": {
                color: "nocdBlue.dark",
              },
            }}
          >
            View unsigned sessions
          </MuiLink>
        </Box>
      ) : null}
    </Stack>
  );
};
export default SessionCounter;
