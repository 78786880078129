import { useSession } from "@core/hooks/useSession";
import {
  api,
  DefaultResponse,
  transformAPIError,
} from "@core/services/nocd-api";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import getQueryKeys from "../query-keys";

interface GoalPayload {
  goal_type: string;
  goal: number;
}

const updateConquerorGoal = (
  payload: GoalPayload,
  accessToken: string | undefined
) => {
  return api
    .post<DefaultResponse>(
      `/v3/clinicians/dashboard/progress/conqueror_goal`,
      payload,
      accessToken
        ? {
            headers: {
              Authorization: accessToken,
            },
          }
        : undefined
    )
    .then(({ data }) => data)
    .catch(transformAPIError);
};

const useUpdateConquerorGoal = (): UseMutationResult<
  DefaultResponse,
  Error,
  GoalPayload
> => {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};
  const queryClient = useQueryClient();

  return useMutation((payload) => updateConquerorGoal(payload, accessToken), {
    onSuccess: (_data) =>
      queryClient.invalidateQueries(getQueryKeys(accessToken).metrics()),
  });
};
export default useUpdateConquerorGoal;
