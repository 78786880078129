import { useSession } from "@core/hooks/useSession";
import { useQuery, UseQueryOptions } from "react-query";

import api from "../../api";
import { transformAPIError } from "../../utils";
import getQueryKeys from "../query-keys";
import { TreatmentAdherenceBonus } from "../types";

const getTreatmentAdherenceBonus = (
  clinicianEmail: string,
  accessToken: string,
  signal: AbortSignal
) =>
  api
    .get<TreatmentAdherenceBonus>(
      "/v1/clinicians/dashboard/treatment_adherence_bonus",
      {
        signal,
        headers: {
          Authorization: accessToken,
        },
        params: { email: clinicianEmail },
      }
    )
    .then(({ data }) => data)
    .catch(transformAPIError);

export default function useTreatmentAdherenceBonus(
  clinicianEmail: string,
  options: UseQueryOptions<TreatmentAdherenceBonus, Error>
) {
  const { data: session } = useSession();
  const { accessToken } = session ?? {};

  return useQuery(
    getQueryKeys(accessToken).treatmentAdherenceBonus(clinicianEmail),
    ({ signal }) =>
      getTreatmentAdherenceBonus(clinicianEmail, accessToken, signal),
    {
      enabled: !!accessToken,

      staleTime: Infinity,
      ...(options ?? {}),
    }
  );
}
