const getMemberFullNameAndLinkRoleIcon = (
  firstName: string,
  lastName: string,
  preferredName: string,
  is_linked_account?: 1 | 0 | null,
  is_root_account?: 1 | 0 | null
) => {
  const formattedPreferredName = preferredName
    ? `"${preferredName}"`
    : undefined;

  return [
    firstName,
    formattedPreferredName,
    lastName,
    is_linked_account === 1 ? "🔗 (Child)" : null,
    is_root_account === 1 ? "👑 (Parent)" : null,
  ]
    .filter(Boolean)
    .join(" ");
};

export default getMemberFullNameAndLinkRoleIcon;
